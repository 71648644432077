export default {
  state: {
    moondays: [
      {
      number: '0',
      energy: '0',
      parts: '0',
      nutrition: '0',
      physics: '0',
      },
      {
      number: '1',
      energy: 'активный день',
      parts: 'лицо, глаза, переносица, мозг',
      nutrition: 'ограничить острую и горячую пищу, алкоголь',
      health: 'будьте осторожны, сейчас ваш организм абсолютно незащищен, поэтому высока вероятность получить какую-либо травму или заболеть. Лучше всего провести этот день в покое и расслаблении',
      physics: 'руки – не перегружать, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      },
      {
      number: '2',
      energy: 'пассивный день',
      parts: 'рот, зубы',
      nutrition: 'второй лунный день хорош для голодания, установления правильной диеты. Хорошо в этот день начать цикл физических упражнений',
      health: 'благоприятное время для начала новых оздоровительных программ, здорового образа жизни. Хорошо приступить к выполнению нового комплекса упражнений, направленных на общее оздоровление и профилактику, при этом упражнения следует подбирать так, чтобы они представляли собой «золотую середину» между нагрузкой и расслаблением',
      physics: 'руки – не перегружать, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      },
      {
      number: '3',
      energy: 'активный день',
      parts: 'затылок, уши',
      nutrition: 'не перегружать',
      health: 'самым полезным для здоровья будут активные физические упражнения, лыжные походы, бег на марафонские дистанции и тому подобное. Физические нагрузки в третьи лунные сутки полезны тем, что могут дать выход накапливающейся энергии вовне. Это очень хороший период для участия в соревнованиях, весьма поощрительны занятия боевыми искусствами. Желательно сходить в сауну и попариться',
      physics: 'руки – с осторожностью, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      },
      {
      number: '4',
      energy: 'пассивный день',
      parts: 'гортань, шея',
      nutrition: 'не перегружать',
      health: 'работающим за компьютером рекомендуется чаще отдыхать и разминать шею, а тем, кому по работе приходится много говорить, например, учителям или лекторам, желательно в этот период как можно больше молчать, а если такой возможности нет, то чаще пить чай и не перенапрягать голосовые связки. Очень полезно для здоровья в четвертый лунный день совершить прогулку в лес или парк',
      physics: 'руки – не перегружать, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      },
      {
      number: '5',
      energy: 'активный день',
      parts: 'пищевод',
      nutrition: 'в этот день следует уделять употребляемой пище большое внимание. Желательно исключить грибы, семечки, орехи, квашенную капусту, мучные изделия. В 5 лунный день надо насытиться, но не переедать',
      health: 'физические упражнения должны быть больше направлены на внутреннюю работу. Хорошо в пятый лунный день прорабатывать сухожилия, весьма полезны упражнения на растяжение мышц. Подобный подбор упражнений поможет организму быстрее и без осложнений пройти период внутренней трансформации',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      },
      {
      number: '6',
      energy: 'пассивный день',
      parts: 'бронхи, верхние дыхательные пути',
      nutrition: 'в этот день следует уделять употребляемой пище большое внимание. В этот день пища сгорает без остатка и полностью преобразуется в ткани. Важно насытиться, но не переедать. Как основа питания предпочтительнее молоко и творо',
      health: 'опасно перенапрячь голосовые связки, то есть "сорвать" голос. Следует воздержаться от употребления холодных напитков - дыхательные пути и горло весьма слабы, их нужно поберечь. Хорошо в этот период выполнить программы, предназначенные для омоложения организма. Очень полезным будет массаж',
      physics: ' руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      },  
      {
      number: '7',
      energy: 'активный день',
      parts: 'легкие, руки',
      nutrition: 'желательно не употреблять куриные яйца и мясо',
      health: 'в седьмые лунные сутки полезно будет провести небольшой период молчания, хотя бы полдня. Это даст хороший отдых вашей нервной системе, поможет восстановить психические силы и дать голосовым связкам долгожданный отдых',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – не перегружать, ноги – не перегружать',
      }, 
      {
      number: '8',
      energy: 'пассивный день',
      parts: 'желудок',
      nutrition: 'сухой голод, легкое питание. Полезны очистительные практики. Исключить тяжелую и жирную пищу',
      health: ' в 8 лунный день весьма приветствуются массажные процедуры и сеансы ароматерапии. В данный период уязвима нервная система, поэтому нужно по возможности соблюдать покой, не брать на себя чрезмерные физические нагрузки, не усердствовать на тренировках',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – не перегружать, ноги – с осторожностью',
      },
      {
      number: '9',
      energy: 'активный день',
      parts: 'грудь',
      nutrition: 'полезно голодание с водой, употребление рыбы. Грибы лучше исключить',
      health: 'в девятые лунные сутки необходимо обратить особое внимание на любые недомогания, обострение каких-либо процессов. Полезно проводить всевозможные очистительные практики',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – не перегружать, ноги – с осторожностью',
      },
      {
      number: '10',
      energy: 'активный день',
      parts: 'кости грудной клетки',
      nutrition: 'полезно употребление жидкости, чаепитие. Исключить сухое голодание',
      health: 'лучше всего посвятить этот день семейным занятиям физкультурой, походам в лес, на речку, совместным марафонам и так далее. Это наилучший день для русской паровой бани',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – не перегружать, ноги – не перегружать',
      },
      {
      number: '11',
      energy: 'активный день',
      parts: 'позвоночник',
      nutrition: 'старайтесь употреблять меньше грубой пищи, пить больше воды и соков. Хороший день для проведения голодания',
      health: 'начавшиеся в этот день недуги опасны в основном для женщин. Период активных физических упражнений. Очень хорошо сейчас заниматься боевыми искусствами, без применения холодного оружия. На тренировках можно увеличивать нагрузку, осваивать новые движения и упражнения. В одиннадцатые лунные сутки хорошо переходить на новый уровень в тех оздоровительных программах, которые были начаты в начале лунного месяца. У того, кто проводит одиннадцатый лунный день в пассивности, обостряются хронические проблемы со здоровьем. В этот день полезно купаться',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – не перегружать, ноги – не перегружат',
      },
      {
      number: '12',
      energy: 'пассивный день',
      parts: 'сердце',
      nutrition: 'в этот день надо стараться употреблять поменьше грубой пищи, воды - много. Рекомендуются соки (кроме яблочного)',
      health: 'в двенадцатые лунные сутки не стоит перегружаться тяжелыми тренировками, а следует оберегать себя стрессов и нервных срывов. Лучше всего провести сеанс медитации. В этот период желательно получить мягкий массаж',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – не перегружать, ноги – с осторожностью',
      }, 
      {
      number: '13',
      energy: 'активный день',
      parts: 'желудок, поджелудочная железа',
      nutrition: 'здоровое полноценное питание, употребление травяных настоев. Избегать голодания',
      health: 'тринадцатый лунный день - период коррекции. Вы можете усовершенствовать и подогнать под себя те оздоровительные программы, которые начали во вторые лунные сутки. Можете внести изменения, одни упражнения заменить другими, увеличить или сократить время выполнения всего комплекса и так далее. Очень полезны массажи и водные процедуры',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      }, 
      {
      number: '14',
      energy: 'пассивный день',
      parts: 'кишечник',
      nutrition: 'данный период наиболее благоприятен для очищения организма. Есть лучше побольше миндаля и соленых продуктов, отказаться от горького и сладкого',
      health: 'необходимо по возможности меньше работать за компьютером, воздержаться от чтения и просмотра телевизора. Нежелательно сейчас курить или находиться рядом с курильщиками',
      physics: 'руки – не перегружать, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      },
      {
      number: '15',
      energy: 'активный день',
      parts: 'диафрагма, поджелудочная железа',
      nutrition: 'желательно есть калину - для улучшения памяти. Пища хороша как острая, так и постная, горячая. Рекомендуется японская кухня: бобовые, ячмень. Нежелательно есть капусту, лук, яйца, яблоки',
      health: 'для организма это очень опасный период. Многие недуги могут неожиданно обостриться, в особенности хронические. Астрологи рекомендуют в этот период проводить больше медитативных упражнений. Очень полезно будет получить сеанс массажа',
      physics: 'руки – с осторожностью, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      }, 
      {
      number: '16',
      energy: 'пассивный день',
      parts: 'селезенка',
      nutrition: 'не рекомендуется употреблять животной пищи (кроме рыбы) и грибов',
      health: 'показаны физические упражнения, процедуры очистки. День весьма хорош для очистительных процедур, омовений, травяных ванн, сеансов ароматерапии и подобных процедур. Удачными будут тренировки, требующие участия партнера, а также парная йога или массаж',
      physics: 'руки – не перегружать, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      },
      {
      number: '17',
      energy: 'активный день',
      parts: 'почки, надпочечники, эндокринная система, гипофиз',
      nutrition: 'легкое очищающее питание, соблюдайте осторожность в плане приема алкоголя и переедания',
      health: 'для здоровья это весьма благоприятный период, когда на какое-то время отступают даже хронические недуги. На этот лунный период в тренировках и оздоровительных программах можно сделать паузу',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      }, 
      {
      number: '18',
      energy: 'пассивный день',
      parts: 'кожа',
      nutrition: 'нежелательно употреблять мясную пищу - лучше орехи, побольше растительного масла. Очень полезны в этот период травяные чаи с добавлением куркумы и корицы',
      health: 'это благоприятное время для наложения масок, примочек, компрессов и принятия травяных ванн. Рекомендуется очищение кожи, массаж, омовение, омоложение, бани с хорошим паром, меньше спать - иначе можно встать в разбитом состоянии',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      },
      {
      number: '19',
      energy: 'активный день',
      parts: 'пупочный центр, аппендикс, кишечник',
      nutrition: 'в этот день есть лучше простую пищу из круглых овощей - капусты, репы, картофеля, свеклы',
      health: 'все тренировки и занятия желательно проводить с осторожностью, дабы не получить ушибы, растяжения и другие травмы. В это время вы можете почувствовать себя сильным, но это только видимость',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – не перегружать, ноги – не перегружать',
      },
      {
      number: '20',
      energy: 'пассивный день',
      parts: 'верхняя часть спины, лопатки, плечи, брюшина',
      nutrition: 'ограничьте калории, откажитесь от мучного и сладкого, продуктов животного происхождения, следите за качеством питания',
      health: 'показаны фитотерапия и дыхательная гимнастика, очистительные процедуры, в особенности тонизирующие массажи, в частности массаж спины. Тем, кто усиленно занимается культуризмом, тяжелой атлетикой или боевыми искусствами, рекомендуется воздержаться от больших нагрузок. Лучше поработать с другими группами мышц. Самыми полезными сегодня будут упражнения, выполняемые с партнером, а в идеале, чем больше людей участвует, тем лучше',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – с осторожностью, ноги – не перегружать.',
      }, 
      {
      number: '21',
      energy: 'активный день',
      parts: 'печень, кровь',
      nutrition: 'показана физическая активность, фитотерапия, гигиена, любое очищение. Легко ради здоровья бросаются дурные привычки. Необходимо есть морковь, клюкву, свеклу, рябину, гранаты',
      health: 'рекомендуется выполнять физические упражнения, ванны, души, обливания, закаливание. Сегодня на тренировках можно увеличить нагрузку, это пойдет только на пользу',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – не перегружать, ноги – с осторожностью',
      },
      {
      number: '22',
      energy: 'пассивный день',
      parts: 'тазобедренный пояс, крестец',
      nutrition: 'нельзя голодать и ограничиваться в питании, предпочтение отдать вегетарианской пище',
      health: 'если вы занимаетесь поднятием тяжестей, то постарайтесь не выполнять сегодня упражнений, с сильной нагрузкой. В двадцать вторые лунные сутки рекомендуется начинать новую оздоровительную программу',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – не перегружать, ноги – не перегружать',
      },
      {
      number: '23',
      energy: 'активный день',
      parts: 'женские органы, яичники',
      nutrition: 'исключается из употребления мясная пища, желательна молочная - надо пить больше молока, есть творог и другие молочные продукты, печь ватрушки, в которые хорошо запечь орехи',
      health: 'возможно обострение хронических проблем или различные травматические ситуации. Сегодня рекомендуется отменить все тренировки, даже самые щадящие, так как очень сложно предсказать, что в состоянии вызвать нежелательные последствия',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – с осторожностью, ноги – с осторожностью',
      },
      {
      number: '24',
      energy: 'пассивный день',
      parts: 'наружные половые органы, анус',
      nutrition: 'рекомендуется плотное питание, и меньше жидкости',
      health: 'рекомендуются силовые физические упражнения, йога. Благоприятен для пробуждения и преобразования сексуальной энергии. Этот день надо активно использовать для укрепления собственного здоровья. Вредные факторы для вашего организма в этот день - курение и алкоголь',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – не перегружать, ноги – с осторожностью',
      },
      {
      number: '25',
      energy: 'активный день',
      parts: 'уши',
      nutrition: 'ограничить употребление лекарств, вина, грубой пищи, курение',
      health: 'если вы болели, то ваше самочувствие останется прежним, боли не усилятся, но и не уйдут. Здоровым же людям очень полезно искупаться в речке, озере, море. Если такой возможности нет, обязательно примите ванну и желательно с травами',
      physics: 'руки – не перегружать, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      },   
     {
      number: '26',
      energy: 'пассивный день',
      parts: 'бедра, кожа',
      nutrition: 'не перегружать',
      health: 'весьма уязвимый период, достаточно малейшего повода, чтобы получить травмы. Полезны массаж и косметологические процедуры, работа с акупунктурными точками',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      }, 
      {
      number: '27',
      energy: 'активный день',
      parts: 'голень',
      nutrition: 'не перегружать',
      health: 'сегодня употребление химических препаратов может вызвать непредвиденную реакцию организма, различные осложнения. В 27 лунный день нужно поменьше находиться на сквозняке, и главное - избегать сырости',
      physics: 'руки – нагрузка в обычном режиме, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      },
      {
      number: '28',
      energy: 'пассивный день',
      parts: 'глаза, головной мозг',
      nutrition: 'не перегружать',
      health: 'большие нагрузки допустимы, но обычно не рекомендуются. В двадцать восьмые лунные сутки можно делать различные очистительные комплексы. Полезны водные процедуры - от плавания до купания в проруби. Хорошо принять травяную ванну',
      physics: 'руки – с осторожностью, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      }, 
      {
      number: '29',
      energy: 'активный день',
      parts: 'анус, прямая кишка',
      nutrition: 'в обычном режиме',
      health: 'рекомендуется как можно меньше нагружать себя. Избегайте сквозняков и сырых помещений. На тренировках будьте осторожны - день чреват обилием травм, которые могут закончиться нешуточными осложнениями. В двадцать девятые лунные сутки могут вновь дать о себе знать те недуги, о которых вы уже и думать забыли',
      physics: 'руки – не перегружать, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      },  
      {
      number: '30',
      energy: 'пассивный день',
      parts: 'гипофиз',
      nutrition: 'В обычном режиме. Пища должна быть легкой, не мясной, не грубой',
      health: 'полезно провести сеанс массажа или принять травяную ванну, в крайнем случае просто посидеть в молчании и расслаблении. Напрягаться в тридцатые лунные сутки не рекомендуется. На тренировках лучше провести теоретическое занятие или уменьшить нагрузки до минимума',
      physics: 'руки – не перегружать, верхняя часть туловища – нагрузка в обычном режиме, нижняя часть туловища – нагрузка в обычном режиме, ноги – нагрузка в обычном режиме',
      },                                         
    ],
  },
  mutations: {},
  actions: {},
  getters: {
    moondays (state) {
      return state.moondays
    }
  }
}
