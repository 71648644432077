<template>
    <v-container>

    <v-row justify="center">
      <v-btn
            depressed
            color="primary"
            width="382px"
          >
      Ваша дата рождения:  {{this.birthday}}
    </v-btn>

    </v-row>
    <v-row justify='center'>
        <p></p>
    </v-row>

        <v-row justify="center">
      <v-btn
            depressed
            width="382px"
          >
      Ваш физический биоритм:  {{this.Bio23}}
    </v-btn>

    </v-row>
    <v-row justify='center'>
        <p></p>
    </v-row>

    <v-row justify='center'>
            <v-btn
            depressed
            width="382px"
          >
        <v-progress-linear v-bind:value='this.slider23' ></v-progress-linear>
            </v-btn>
        </v-row>

    <v-row justify='center'>
        <p></p>
    </v-row>

        <v-row justify="center">
      <v-btn
            depressed
            width="382px"
          >
      Ваш эмоциональный биоритм:  {{this.Bio28}}
    </v-btn>

    </v-row>
    <v-row justify='center'>
        <p></p>
    </v-row>

        <v-row justify='center'>
            <v-btn
            depressed
            width="382px"
          >
        <v-progress-linear v-bind:value='this.slider28'></v-progress-linear>
            </v-btn>
        </v-row>

    <v-row justify='center'>
        <p></p>
    </v-row>

        <v-row justify="center">
      <v-btn
            depressed
            width="382px"
          >
      Ваш интеллектуальный биоритм:  {{this.Bio33}}
    </v-btn>

    </v-row>
    <v-row justify='center'>
        <p></p>
    </v-row>

        <v-row justify='center'>
            <v-btn
            depressed
            width="382px"
          >
        <v-progress-linear v-bind:value='this.slider33'></v-progress-linear>
            </v-btn>
        </v-row>

    <v-row justify='center'>
        <p></p>
    </v-row>

        <v-row justify='center'>
    <v-card width="382" height="250px" elevation="2">
        <v-card-title>
            АВТОРЫ ПРОЕКТА:
        </v-card-title>

        <v-card-text>
            Румянцева Екатерина (идея проекта)<br>
            Горбунов Валерий (дизайн, программирование)
            
        </v-card-text>

    </v-card>

    </v-row>
    

    </v-container>
</template>

<script>
    export default {
    data: () => ({
            sex: "2",
            birthday: '1969-08-25',
            Bio23: 50,
            Bio28: 50,
            Bio33: 50,
            slider23: 50,
            slider28: 50,
            slider33: 50,
            }),
    methods: {
      calculate_biorhithm(){
        let date1 =  new Date(this.birthday);
        let date2 = new Date();
        let timeDiff = Math.abs(date2.getTime() - date1.getTime());
        let T = Math.ceil(timeDiff / (1000 * 3600 * 24)); 
        //console.log(T);

        //B = (sin (2pi*t/P))*100 %,
        //console.log('Физический биоритм');
        this.Bio23 = Math.round((Math.sin((2 * Math.PI * T) / 23)) * 100);

       this.slider23 = (+this.Bio23 + 100) * 0.5;
     // console.log(this.slider23);
    

        //console.log('Эмоциональный биоритм');
        this.Bio28 = Math.round((Math.sin((2 * Math.PI * T) / 28)) * 100);

      this.slider28 = (+this.Bio28 + 100) * 0.5;
     // console.log(this.slider28);      
        
        //console.log('Интеллектульный биоритм');
        this.Bio33 = Math.round((Math.sin((2 * Math.PI * T) / 33)) * 100);

        this.slider33 = (+this.Bio33 + 100) * 0.5;
     // console.log(this.slider33);

        //let lune = require('lune')
        //let current_phase = lune.phase()
        //console.log(current_phase)
        //console.log(current_phase['age'])

      },
      load_data(){
        if (localStorage.storage_birthday) {
          this.birthday = localStorage.storage_birthday;
        }
        if (localStorage.storage_sex) {
          this.sex = localStorage.storage_sex;
        }
      }
    },
    created() {
        this.load_data();
        this.calculate_biorhithm();
    },
    updated(){
        this.load_data();
        this.calculate_biorhithm();
    },
    mounted() {
        this.load_data();
        this.calculate_biorhithm();
      }
    }
</script>