<template>
    <v-container>
    <v-row justify="center">
      <v-btn
            depressed
            color="primary"
            width="382px"
          >
      ваша дата рождения:  {{this.birthday}}
    </v-btn>
    </v-row>
    <v-row justify="space-around">
            <v-card width="382">

        <v-img
          height="532px"
          width="382px"
          :src="require(`../images/${mainImage}`)"
        >

        <v-img
          height="532px"
          width="382px"
          :src="require(`../images/${painImage}`)"
        >

        <v-img
          height="532px"
          width="382px"
          :src="require(`../images/${arrowImage}`)"
        >


      </v-img>
        
   </v-img>


</v-img>

      </v-card>

    </v-row>
  </v-container>
</template>

<script>

    import { moon_day } from '../modules/moonday'

    export default {
      data: () => ({
          sex: "2",
          birthday: '1969-08-25',
      }),
      methods: {
      load_data(){
        if (localStorage.storage_birthday) {
          this.birthday = localStorage.storage_birthday;
        }
        if (localStorage.storage_sex) {
          this.sex = localStorage.storage_sex;
        }
      },
      calculateImages(){

        if (this.sex === '1'){
          this.seximg = 'm'
        }else{
          this.seximg = 'f'
        }
        
        this.seximg;
        this.mainImage = moon_day + '.png';
        this.painImage = moon_day + this.seximg + '.png';
        this.arrowImage = moon_day + '00' + this.seximg + '.png'; 

        //console.log('mainImage = '  + this.mainImage );
        //console.log('painImage = '  + this.painImage );
        //console.log('arrowImage = '  + this.arrowImage );
        
        },
        calculateZodiac(){

        //console.log('Zodiac');

        const dateB = new Date(this.birthday)
        var dayOfMonth = dateB.getDate();
        var month = dateB.getMonth() + 1;

        //console.log(dayOfMonth);
        //console.log(month);

        let zodiacSign = learn_sign(month, dayOfMonth);
        console.log(zodiacSign);

        function learn_sign(this_month, date) {
    
          let value;

          if (this_month==1 && date>=20 || this_month==2 && date<=18) value="Водолей";
              else if (this_month==2 && date>=19 || this_month==3 && date<=20) value="Рыбы";
              else if (this_month==3 && date>=21 || this_month==4 && date<=19) value="Овен";
              else if (this_month==4 && date>=20 || this_month==5 && date<=20) value="Телец";
              else if (this_month==5 && date>=21 || this_month==6 && date<=21) value="Близнецы";
              else if (this_month==6 && date>=22 || this_month==7 && date<=22) value="Рак";
              else if (this_month==7 && date>=23 || this_month==8 && date<=22) value="Лев";
              else if (this_month==8 && date>=23 || this_month==9 && date<=22) value="Дева";
              else if (this_month==9 && date>=23 || this_month==10 && date<=22) value="Весы";
              else if (this_month==10 && date>=23 || this_month==11 && date<=21) value="Скорпион";
              else if (this_month==11 && date>=22 || this_month==12 && date<=21) value="Стрелец";
              else if (this_month==12 && date>=22 || this_month==1 && date<=19) value="Козерог";
          else value="Неверная дата!";
    
    return value;
      }

        },
      },
      
      created () {

        this.load_data();
        this.calculateImages();
        this.calculateZodiac();

      }, updated (){

        this.load_data();
        this.calculateImages();
        this.calculateZodiac();

      },
      mounted() {
        this.load_data();
      }

    }

    // :src="require('../images/1.png')"
</script>
