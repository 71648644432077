<template>
    <v-container>
    <v-row justify="center">
      <v-btn
            depressed
            color="primary"
            width="382px"
          >
      Дата рождения:  {{this.birthday}} : ({{this.zodiac}})
    </v-btn>

    </v-row>
    <v-row justify='center'>
        <p></p>
    </v-row>

    <v-row justify="center">
    
          <v-btn
            depressed
            color="primary"
            width="382px"
          >
      Лунный день:  {{this.moon_day_local}}
    </v-btn>
    </v-row>
    <v-row justify="space-around">
    </v-row>

    <v-row justify='center'>
        <p></p>
    </v-row>

    <v-row justify='center'>
    <v-card width="382" elevation="2">
        <v-card-title>
            Энергия дня:
        </v-card-title>

        <v-card-text>
            {{this.now_energy}}
        </v-card-text>

    </v-card>
    </v-row>

     <v-row justify='center'>
         <p></p>
    </v-row>

    <v-row justify='center'>
    <v-card width="382" elevation="2">
        <v-card-title>
            Активные органы:
        </v-card-title>

        <v-card-text>
            {{this.now_parts}}
        </v-card-text>

    </v-card>
    </v-row>

    <v-row justify='center'>
         <p></p>
    </v-row>

    <v-row justify='center'>
    <v-card width="382" elevation="2">
        <v-card-title>
            Рекомендации по питанию:
        </v-card-title>

        <v-card-text>
            {{this.now_nutrition}}
        </v-card-text>

    </v-card>
    </v-row>

    <v-row justify='center'>
     <p></p>
    </v-row>

    <v-row justify='center'>
    <v-card width="382" elevation="2">
        <v-card-title>
            Рекомендации по здоровью:
        </v-card-title>

        <v-card-text>
            {{this.now_health}}
        </v-card-text>

    </v-card>

    </v-row>

    <v-row justify='center'>
        <p></p>
    </v-row>

    <v-row justify='center'>
    <v-card width="382" elevation="2">
        <v-card-title>
            Физическая активность:
        </v-card-title>
        <v-card-text>
            {{this.now_physics}}
        </v-card-text>
    </v-card>
    </v-row>



</v-container>
</template>

<script>

    import { moon_day } from '../../modules/moonday'

    export default {
        data: () => ({
            now_energy: 'calculated',
            now_parts: 'calculated',
            now_nutrition: 'calculated',
            now_physics: 'calculated',
            now_health: 'calculated',
            sex: "2",
            birthday: '1969-08-25',
            zodiac: 'calculated',
            moon_day_local: 'calculated'
      }),
      methods: {
        load_data(){
            if (localStorage.storage_birthday) {
            this.birthday = localStorage.storage_birthday;
            }
            if (localStorage.storage_sex) {
            this.sex = localStorage.storage_sex;
            }
        },
        calculateZodiac(){

        console.log('Zodiac');

        const dateB = new Date(this.birthday)
        var dayOfMonth = dateB.getDate();
        var month = dateB.getMonth() + 1;

        console.log(dayOfMonth);
        console.log(month);

        let zodiacSign = learn_sign(month, dayOfMonth);
        console.log(zodiacSign);

        function learn_sign(this_month, date) {
    
          let value;

          if (this_month==1 && date>=20 || this_month==2 && date<=18) value="Водолей";
              else if (this_month==2 && date>=19 || this_month==3 && date<=20) value="Рыбы";
              else if (this_month==3 && date>=21 || this_month==4 && date<=19) value="Овен";
              else if (this_month==4 && date>=20 || this_month==5 && date<=20) value="Телец";
              else if (this_month==5 && date>=21 || this_month==6 && date<=21) value="Близнецы";
              else if (this_month==6 && date>=22 || this_month==7 && date<=22) value="Рак";
              else if (this_month==7 && date>=23 || this_month==8 && date<=22) value="Лев";
              else if (this_month==8 && date>=23 || this_month==9 && date<=22) value="Дева";
              else if (this_month==9 && date>=23 || this_month==10 && date<=22) value="Весы";
              else if (this_month==10 && date>=23 || this_month==11 && date<=21) value="Скорпион";
              else if (this_month==11 && date>=22 || this_month==12 && date<=21) value="Стрелец";
              else if (this_month==12 && date>=22 || this_month==1 && date<=19) value="Козерог";
          else value="Неверная дата!";
    
        return value;
      }

      return zodiacSign;

        },
      },
      computed: {
          moondays() {
              return this.$store.getters.moondays
          }
      },
     created () {

        //console.log (this.moondays[2]['parts']);

       this.load_data();
       this.moon_day_local = moon_day;
       this.zodiac = this.calculateZodiac();
       //console.log (moon_day);


        this.now_energy = this.moondays[moon_day]['energy'];
        this.now_parts = this.moondays[moon_day]['parts'];
        this.now_nutrition = this.moondays[moon_day]['nutrition'];
        this.now_health = this.moondays[moon_day]['health'];
        this.now_physics = this.moondays[moon_day]['physics'];

      }, 
      
      updated (){

         this.load_data(); 
         this.moon_day_local = moon_day;
         this.zodiac = this.calculateZodiac();

      },
      mounted() {

        this.load_data();
        this.moon_day_local = moon_day;
        this.zodiac = this.calculateZodiac();
    },
    }
</script>