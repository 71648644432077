<template>
      <v-container grid-list-md text-xs-center>

    <v-row justify="center">
    <v-btn
        depressed
        color="primary"
        >
      Укажите Вашу дату рождения
    </v-btn>
    </v-row>
         <v-row justify="center">
            <v-date-picker
            v-model="birthday"
            class="mt-4"
            min="1930-01-01"
            max="2021-01-01"
            ></v-date-picker>
         </v-row>

        <v-row justify="center">
            <v-btn
                depressed
                color="primary"
                >
            Укажите Ваш пол
            </v-btn>
        </v-row>

        <v-row justify="center">
          <v-form>
            <v-radio-group row v-model="sex" class="justify-center">
              <v-radio value="1" label="МУЖ"></v-radio>
              <v-radio value="2" label="ЖЕН"></v-radio>
            </v-radio-group>
          </v-form>

        </v-row>

      </v-container>
</template>
<script>


  export default {
    data: () => ({
        sex: "2",
        birthday: '1969-08-25',
    }),
    methods: {
      load_data(){
        if (localStorage.storage_birthday) {
          this.birthday = localStorage.storage_birthday;
        }
        if (localStorage.storage_sex) {
          this.sex = localStorage.storage_sex;
        }
      }
    },
    updated (){
      this.load_data();
    },
    created (){

        this.load_data();

      },mounted() {
        this.load_data();
  },
  watch: {
      birthday() {
        localStorage.storage_birthday = this.birthday;
      },
      sex() {
        localStorage.storage_sex = this.sex;
      }
    }  
  }
</script>